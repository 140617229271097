<template>
<v-ons-page class="terms">
	<common-header
		type="allow"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">利用規約</h1>
	</common-header>
	<div class="terms__container" id="js_terms_container">
		<h2 class="terms__title">利用規約</h2>
		<article class="terms__box">
			<section>
				<p>
					「頭痛ーる&thinsp;forスゴ得ご利用規約」（以下「本規約」といいます）は、「スゴ得コンテンツご利用規約」第3条第4項又は「スゴ得コンテンツ（前払いコース）ご利用規約」第3条第4項に基づき、株式会社NTTドコモ（以下「当社」といいます）が提供する「頭痛ーる&thinsp;forスゴ得」（以下「本コンテンツ」といいます）の提供条件等を定めるものです。本コンテンツのご利用にあたっては、「スゴ得コンテンツご利用規約」又は「スゴ得コンテンツ（前払いコース）ご利用規約」のほか、「本規約」も適用されます。お客さまが「スゴ得コンテンツご利用規約」又は「スゴ得コンテンツ（前払いコース）ご利用規約」および本規約（これらを総称して以下「本規約等」といいます）に同意されない場合、本コンテンツを利用することはできません。お客さまが本コンテンツのご利用を開始された場合は、本規約等に同意されたものとみなされます。
				</p>
			</section>
			<section>
				<h3>第1条(免責)</h3>
				<ul class="terms__list">
					<li>
						1.本コンテンツは、医療行為、診療行為又はこれに準ずる行為一切を、目的としておりません。したがって、本コンテンツ上における、いかなる投稿、返答、情報なども治療行為ではありません。お客さまはこのことを承諾した上、自己責任において本サービスを利用し、必要に応じて、正しい情報の検索、医療機関の受診など、自身の判断で行ってください。本コンテンツの利用を通じて損害を被った場合でも、当社はそれに対し一切の責任を負いませんので予めご了承ください。
					</li>
					<li>
						2.本コンテンツはお客さまの体調管理をサポートするものであり、当社は、お客さまの健康状態や頭痛を引き起こす可能性について何ら保証をするものではありません。本コンテンツの内容については、正確かつ最新の情報を保つように努めますが、正確性や妥当性についてその完全性を保証するものではありません。本コンテンツの利用によりお客さまに不都合、不利益が発生した場合でも、当社ならびに監修者は責任を負いません。
					</li>
				</ul>
			</section>
			<section>
				<h3>第2条(お客さま情報の取り扱い)</h3>
				<ul class="terms__list">
					<li>
						1.本コンテンツで収集した情報については、以下の目的の範囲内で利用します。なお、当社はお客さま個人を特定する情報（個人情報）を取得することはございません。また、お客さまの体調管理情報の取り扱いについては「データの取り扱いについて」に記載しています。
						<ul class="terms__indent-list">
							<li>・個人を識別することができない状態での統計資料、本コンテンツの向上およびマーケティングなどの目的で情報を集計および分析などを行うため。</li>
							<li>・上記目的に付帯する目的のため。</li>
						</ul>
					</li>
					<li>
						2.前項以外の目的で、当社はお客さまの情報を第三者に開示・貸出等を行わないものとします。ただし、以下の場合はこの限りにありません。
						<ul class="terms__indent-list">
							<li>・情報開示や共有についてお客さまの同意がある場合。</li>
							<li>・裁判所や警察等の公的機関から、法令に基づく正式な照会要請を受けた場合。</li>
							<li>・法令の定めに拠る場合。</li>
						</ul>
					</li>
					<li>
						3.当社は、本サービスを通じて、情報をgoogle analyticsを利用することにより解析し、分析した結果を第三者に開示・販売・貸出等をすることができるものとします。なお、当該解析に必要な情報に、お客さまを特定することが可能な情報は含まれません。
						<ul class="terms__indent-list">
							<li>1）本サービスへの訪問数及び滞在時間</li>
							<li>2）ページビュー数</li>
							<li>3）端末設定言語</li>
							<li>4）地域</li>
							<li>5）ページ遷移</li>
							<li>6）ブラウザ名称</li>
							<li>7）OS</li>
							<li>8）機種名</li>
							<li>9）プロバイダ情報</li>
						</ul>
					</li>
				</ul>
			</section>
			<p class="is-right">以上</p>
		</article>
	</div>
</v-ons-page>
</template>

<script>
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

export default {
	name: 'Terms',
	components: {
		CommonHeader
	},
	data () {
		return {
		}
	},
	computed: {
	},
	mounted () {
		// スゴ得の場合、コンテンツの高さをemitする。
		const domContainer = document.getElementById('js_terms_container')
		if (domContainer) {
			this.$emit('containerHeight', domContainer.clientHeight)
		}
	},
	beforeDestroy () {
		// スゴ得の場合、コンテンツの高さをautoでemitする。
		this.$emit('containerHeight', 'auto')
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";
</style>
